<template>
  <div>
    <div :class="$style.employees">
      <Title text="Сотрудники" position="right" />

      <div :class="$style.employees__header">
        <!-- <Input
          :class="$style.employees__header_input"
          placeholder="Поиск"
          @input="(value) => inputFilter(value)"
          :value="filterValue"
        /> -->
        <div :class="$style.employees__header_count">
          Общее количество пользователей: {{ count }}
        </div>
      </div>

      <div :class="$style.table">
        <div :class="$style.table__header">
          <div :class="$style.table__header_row">
            <div
              :class="$style.table__header_column"
              v-for="headerColumn in headerColumns"
              :key="headerColumn.id"
            >
              {{ headerColumn.title }}
              <img
                v-if="headerColumn.value"
                src="@/assets/icons/arrow.svg"
                alt="arrow"
                @click="sortEmployees(headerColumn.value)"
              />
            </div>
          </div>
        </div>

        <div :class="$style.table__content">
          <div
            v-for="employee in list"
            :key="employee.id"
            :class="[
              $style.table__content_row,
              { [$style.table__content_row_disabled]: employee.activated === false }
            ]"
          >
            <div :class="$style.table__content_column">
              {{ employee.id }}
            </div>

            <div :class="$style.table__content_column">
              {{ `${employee.lastName} ${employee.firstName} ${employee.middleName ?? ''}` }}
            </div>

            <div :class="$style.table__content_column">
              {{ employee.phone }}
            </div>

            <div :class="$style.table__content_column">
              {{ employee.role.name }}
            </div>

            <div :class="$style.table__content_column">
              {{ employee.email }}
            </div>

            <div :class="$style.table__content_column">
              <Button
                style="margin: 0 0.25rem 0 0"
                :class="$style.table__content__button"
                type="table"
                @click="resetEmployeePassword(employee.id)"
              >
                Сброс пароля
              </Button>
              <Button
                :class="$style.table__content__button"
                type="table"
                @click="deleteEmployee(employee.id)"
              >
                Удалить
              </Button>
            </div>

            <div :class="[$style.table__content_column, $style.table__content_status]">
              <img
                :class="$style.table__content__icon"
                :title="employee.activated ? 'Активирован' : 'Неактивирован'"
                :src="
                  require(`@/assets/icons/${
                    employee.activated ? 'verified' : 'not-verified'
                  }.svg`)
                "
                alt="icon"
              />
            </div>
          </div>

          <div :class="$style.table__content_rowLine">
            <div
              v-for="line in headerColumns.length"
              :key="line.id"
              :class="$style.table__content_line"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination
      :pageCount="Math.ceil(count / 10)"
      :prevText="''"
      :click-handler="onPageChange"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      v-if="paginationLoaded && count > 10"
      :initial-page="$route.query.page - 1"
    />
    <Button
      :class="$style.create_button"
      type="tertiary"
      @click="$router.push(`/employees/new`)"
    >
      Добавить сотрудника
    </Button>
  </div>
</template>

<script>
import Title from '@/basic/Title'
// import Input from '@/basic/Input'
import Button from '@/basic/Button'
import employeesApi from '@/api/employees'
// import _ from 'lodash'

const headerColumns = [
  {
    id: 1,
    value: 'id',
    title: 'id'
  },
  {
    id: 2,
    // value: 'fullName',
    title: 'Фио'
  },
  {
    id: 3,
    // value: 'phone',
    title: 'Телефон'
  },
  {
    id: 7,
    title: 'Роль'
  },
  {
    id: 4,
    // value: 'email',
    title: 'Почта'
  },
  {
    id: 5,
    // value: 'status',
    title: 'Действия'
  },
  {
    id: 6,
    // value: 'status',
    title: 'Статус'
  }
]

export default {
  name: 'Employees',
  components: {
    Title,
    // Input,
    Button
  },
  data() {
    return {
      list: [],
      count: 0,
      paginationLoaded: true,
      // filterValue: '',
      // filterValueThrottle: _.throttle(this.inputValueChange, 300, { leading: false }),
      headerColumns,
      // filter: {},
      pagination: {
        limit: 10,
        offset: (this.$route.query.page - 1) * 10
      },
      order: {
        field: 'id',
        by: 'desc'
      }
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    async getList() {
      try {
        const {
          data: {
            result: { count, rows }
          }
        } = await employeesApi.getEmployees({
          pagination: this.pagination,
          order: this.order
        })
        this.list = rows
        this.count = count
      } catch (error) {
        console.warn(error)
        this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: 'Ошибка получения данных по менеджерам с сервера' }
        ])
      }
    },
    // inputFilter(value) {
    //   if (this.source) {
    //     this.source.cancel('[Match users operation]: CANCELED')
    //   }
    //   this.filterValueThrottle(value)
    // },
    // async inputValueChange(value) {
    //   this.filterValue = value
    //   this.paginationLoaded = false
    //   await this.onPageChange(1)
    //   this.paginationLoaded = true
    // },
    async onPageChange(page) {
      if (Number(this.$route.query.page) !== page) {
        await this.$router.push(`?page=${page}`)
      }
      this.pagination.offset = 10 * (page - 1)
      await this.getList()
    },
    async sortEmployees(field) {
      if (this.order.field === field) {
        switch (this.order.by) {
          case 'desc':
            {
              this.order.by = 'asc'
            }
            break
          case 'asc':
            {
              this.order.by = 'desc'
            }
            break
          default:
            {
              this.order.by = 'desc'
              this.order.field = field
            }
            break
        }
      } else {
        this.order = {
          field,
          by: 'desc'
        }
      }
      await this.getList()
    },
    async deleteEmployee(id) {
      try {
        const { data } = await employeesApi.deleteEmployee(id)
        if (data?.success) {
          this.list = this.list.filter((e) => e.id !== id)
          this.count -= 1
          this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Успех', message: 'Менеджер был успешно удален!' }
          ])
        }
      } catch (error) {
        console.warn(error)
        this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: 'Ошибка удаления менеджера на сервере!' }
        ])
      }
    },

    async resetEmployeePassword(id) {
      try {
        const { data } = await employeesApi.resetEmployeePassword(id)
        if (data?.success) {
          this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Успех',
              message: 'Пароль менеджера был успешно сброшен на дефолтный админом!'
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка сервера',
            message: 'Ошибка сброса пароля менеджера на дефолтный админом!'
          }
        ])
      }
    }
  }
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.employees {
  &__header {
    display: flex;
    margin: 0 0 2rem;
    &_input {
      width: 15rem;
      margin: 0 2rem 0 0;
    }
    &_count {
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .table {
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }
      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 7.25%;
        }

        &:nth-child(n + 2) {
          width: 19%;
        }

        &:nth-child(5) {
          width: 25.5%;
        }

        &:last-child {
          width: 9.8%;
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        background: rgba(165, 241, 151, 0.1);
        border-bottom: 1px solid $table-border;

        &_disabled {
          background: rgba(60, 60, 60, 0.1);
        }
      }

      &_column {
        display: flex;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 7.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 19%;
        }

        &:nth-child(5) {
          width: 25.5%;
        }

        &:last-child {
          width: 10%;
          border: none;
        }
      }

      &__button {
        font-size: 0.875rem;
      }

      &_status {
        justify-content: center;
      }

      &_rowLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 19%;
        }

        &:nth-child(5) {
          width: 25.5%;
        }

        &:last-child {
          width: 10%;
          border: none;
        }
      }

      &__icon {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
  }
}
.create_button {
  margin-top: 1rem;
}
</style>
